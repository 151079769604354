// i18next-extract-mark-ns-start inactive-survey
import {Button} from 'components/Button';
import {Content} from 'components/Content';
import {Field, SelectField} from 'components/Field';
import {Loader} from 'components/Loader';
import {Formik, FormikProps, Field as FormikField} from 'formik';
import {graphql} from 'gatsby';
import {Helmet, Trans, useI18next} from 'gatsby-plugin-react-i18next';

import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {QueryParamProvider, StringParam, useQueryParams} from 'use-query-params';
import {ReachAdapter} from 'use-query-params/adapters/reach';
import * as yup from 'yup';
import {StringSchema} from 'yup';
import {Maybe} from '../../types/graphql-types';
import {colors, links} from 'global.config';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;

  label {
    width: 100%;
  }
`;
const ErrorMessage = styled.div`
  position: absolute;
  color: #f5222d;
  bottom: -28px;
`;

const Question = styled.span`
  font-size: 16px;
`;
const TopicWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Topic = styled.label`
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: baseline;
`;

const Wrapper = styled.div`
  min-height: 80vh;
`;

const SuccessMessage = styled.div`
  text-align: center;
  padding: 100px 0;
`;

enum ChurnReason {
  SALES_DROP = 'SALES_DROP',
  PROBLEM_WITH_PLATFORM = 'PROBLEM_WITH_PLATFORM',
  PROBLEM_WITH_WEBSITE = 'PROBLEM_WITH_WEBSITE',
  CLOSED_BUSINESS = 'CLOSED_BUSINESS',
  ECONOMIC_CONDITIONS = 'ECONOMIC_CONDITIONS',
  LACK_SUPPORT = 'LACK_SUPPORT',
  SWITCHED = 'SWITCHED',
  OTHER = 'OTHER'
}
const switchedOptions = [
  'Stripe',
  'Adyen',
  'Redsys',
  'PAYCOMET',
  'Shopify Payments',
  'Square',
  'PayPal',
  'Chargebee',
  '2Checkout',
  'ACI Worldwide',
  'Mollie',
  'Other'
];

const platformOptions = ['Shopify', 'WooCommerce', 'Magento', 'Salesforce', 'PrestaShop', 'Wix'];

type ChurnReasonType =
  | 'SALES_DROP'
  | 'PROBLEM_WITH_PLATFORM'
  | 'PROBLEM_WITH_WEBSITE'
  | 'CLOSED_BUSINESS'
  | 'ECONOMIC_CONDITIONS'
  | 'LACK_SUPPORT'
  | 'SWITCHED'
  | 'OTHER'
  | '';

type InactiveSurveyType = {
  churnReason: Maybe<ChurnReasonType>;
  churnNote: string;
  churnReasonOption: string;
};

const fieldStyle = {
  marginTop: '6px',
  marginRight: '6px'
};

const InactiveSurvey: React.FC = () => {
  const {t} = useI18next();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [{uid, cr, h}] = useQueryParams({uid: StringParam, h: StringParam, cr: StringParam});
  const formikRef = useRef<FormikProps<InactiveSurveyType> | null>(null);

  useEffect(() => {
    if (uid && h) {
      fetch(links.pinpointApi + `user/${uid}?h=${h}`).then((response) => {
        if (!response.ok) {
          window.location.href = links.landing;
        }
        if (cr) {
          formikRef.current?.setFieldValue('churnReason', cr);
          formikRef.current?.submitForm();
        }
      });
    } else {
      window.location.href = links.landing;
    }
  }, []);

  const formData: InactiveSurveyType = {
    churnReason: (cr as ChurnReason) || '',
    churnNote: '',
    churnReasonOption: ''
  };

  const validationSchema = yup.object().shape({
    churnReason: yup.string().required(t('Please choose a reason')),
    churnReasonOption: yup
      .string()
      .when(['churnReason'], {
        is: ChurnReason.PROBLEM_WITH_PLATFORM,
        then: () =>
          yup
            .string()
            .oneOf(platformOptions, t('Please, choose one of the options'))
            .required(t('Please, choose one of the options'))
      })
      .when(['churnReason'], {
        is: ChurnReason.SWITCHED,
        then: (schema: StringSchema) =>
          schema.required(t('Please, tell us which one you switched to'))
      }),
    churnNote: yup.string().when(['churnReason'], {
      is: (churnReason) =>
        [ChurnReason.OTHER, ChurnReason.SWITCHED].includes(churnReason as ChurnReason),
      then: (schema: StringSchema) => schema.required(t('Please explain here'))
    })
  });

  const inputStyle = {width: '100%', marginTop: '8px'};
  const onSubmit = (values: any) => {
    setLoading(true);
    fetch(links.pinpointApi + `user/${uid}?h=${h}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({...values, userId: uid})
    })
      .then((result) => result.json())

      .then((result) => {
        if (result.statusCode >= 400) {
          setError(result.message);
          return;
        }
        setIsSuccess(true);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('Inactive Survey')} | MONEI</title>
        <meta name="robots" content="noindex,nofollow" />
        <link rel="shortcut icon" href="https://assets.monei.com/images/icon.png" />
      </Helmet>
      <Content sx={{padding: '0 20px', maxWidth: '624px', margin: '60px auto 120px'}}>
        {isSuccess ? (
          <SuccessMessage>
            <Trans parent="h1" style={{color: colors.green}}>
              Thanks for your feedback!
            </Trans>
          </SuccessMessage>
        ) : (
          <>
            <Trans parent="h1">Can you please take 1 minute to help us improve?🙏</Trans>
            <Formik<InactiveSurveyType>
              initialValues={formData}
              onSubmit={onSubmit}
              innerRef={formikRef}
              validateOnBlur
              enableReinitialize
              validationSchema={validationSchema}>
              {({handleSubmit, errors, touched, resetForm, values, setFieldValue}) => {
                const resetAndSetValue = (field: string, value: string) => {
                  resetForm();
                  setFieldValue(field, value);
                };
                return (
                  <Form onSubmit={handleSubmit}>
                    <Question>
                      <Trans>
                        Why has your MONEI account been inactive? Please select the reason that’s
                        most relevant to your business.
                      </Trans>
                    </Question>
                    <Topic>
                      <FormikField
                        type="radio"
                        name="churnReason"
                        style={fieldStyle}
                        value={ChurnReason.SALES_DROP}
                        onChange={(ev: ChangeEvent<{value: string}>) =>
                          resetAndSetValue('churnReason', ev.target.value)
                        }
                      />
                      <Trans>We’re still using MONEI, but our sales have gone down</Trans>
                    </Topic>
                    <TopicWrapper>
                      <Topic>
                        <FormikField
                          type="radio"
                          name="churnReason"
                          style={fieldStyle}
                          value={ChurnReason.PROBLEM_WITH_PLATFORM}
                          onChange={(ev: ChangeEvent<{value: string}>) => {
                            resetForm();
                            setFieldValue('churnReason', ev.target.value);
                          }}
                        />
                        <Trans>
                          Integration problems with our e-commerce platform (please note which
                          platform you use)
                        </Trans>
                      </Topic>
                      {values.churnReason === ChurnReason.PROBLEM_WITH_PLATFORM && (
                        <SelectField style={inputStyle} name="churnReasonOption">
                          {platformOptions.map((opt) => (
                            <option value={opt}>{opt}</option>
                          ))}
                        </SelectField>
                      )}
                    </TopicWrapper>

                    <Topic>
                      <FormikField
                        type="radio"
                        name="churnReason"
                        style={fieldStyle}
                        value={ChurnReason.PROBLEM_WITH_WEBSITE}
                        onChange={(ev: ChangeEvent<{value: string}>) =>
                          resetAndSetValue('churnReason', ev.target.value)
                        }
                      />
                      <Trans>Integration problems with our custom-built website</Trans>
                    </Topic>

                    <Topic>
                      <FormikField
                        type="radio"
                        name="churnReason"
                        style={fieldStyle}
                        value={ChurnReason.CLOSED_BUSINESS}
                        onChange={(ev: ChangeEvent<{value: string}>) =>
                          resetAndSetValue('churnReason', ev.target.value)
                        }
                      />
                      <Trans>We have closed our business</Trans>
                    </Topic>

                    <Topic>
                      <FormikField
                        type="radio"
                        name="churnReason"
                        style={fieldStyle}
                        value={ChurnReason.ECONOMIC_CONDITIONS}
                        onChange={(ev: ChangeEvent<{value: string}>) =>
                          resetAndSetValue('churnReason', ev.target.value)
                        }
                      />
                      <Trans>Economic conditions</Trans>
                    </Topic>

                    <Topic>
                      <FormikField
                        type="radio"
                        name="churnReason"
                        style={fieldStyle}
                        value={ChurnReason.LACK_SUPPORT}
                        onChange={(ev: ChangeEvent<{value: string}>) =>
                          resetAndSetValue('churnReason', ev.target.value)
                        }
                      />
                      <Trans>Lack of support from MONEI</Trans>
                    </Topic>

                    <TopicWrapper>
                      <Topic>
                        <FormikField
                          type="radio"
                          name="churnReason"
                          style={fieldStyle}
                          value={ChurnReason.SWITCHED}
                          onChange={(ev: ChangeEvent<{value: string}>) => {
                            resetForm();
                            setFieldValue('churnReason', ev.target.value);
                          }}
                        />
                        <Trans>
                          We have switched to a different solution (please explain which solution
                          and why)
                        </Trans>
                      </Topic>
                      {values.churnReason === ChurnReason.SWITCHED && (
                        <>
                          <SelectField style={inputStyle} name="churnReasonOption">
                            {switchedOptions.map((opt) => (
                              <option value={opt}>{opt}</option>
                            ))}
                          </SelectField>
                          <Field
                            as="textarea"
                            rows={2}
                            name="churnNote"
                            placeholder={t('Please tell us why')}
                          />
                        </>
                      )}
                    </TopicWrapper>

                    <TopicWrapper>
                      <Topic>
                        <FormikField
                          type="radio"
                          name="churnReason"
                          style={fieldStyle}
                          value={ChurnReason.OTHER}
                          onChange={(ev: ChangeEvent<{value: string}>) => {
                            resetForm();
                            setFieldValue('churnReason', ev.target.value);
                          }}
                        />
                        <Trans>Other (please explain)</Trans>
                      </Topic>
                      {values.churnReason === ChurnReason.OTHER && (
                        <Field
                          as="textarea"
                          rows={2}
                          name="churnNote"
                          style={inputStyle}
                          placeholder={t('Please explain here')}
                        />
                      )}
                    </TopicWrapper>
                    <Button type="submit" style={inputStyle} variant="light">
                      {loading ? <Loader /> : <Trans>Save</Trans>}
                    </Button>
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </Form>
                );
              }}
            </Formik>
          </>
        )}
      </Content>
    </Wrapper>
  );
};

const InactiveSurveyPage = () => {
  return (
    <QueryParamProvider adapter={ReachAdapter}>
      <InactiveSurvey />
    </QueryParamProvider>
  );
};

export default InactiveSurveyPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["inactive-survey", "common"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
